
import { defineComponent, ref } from 'vue'
import { IComObj } from '@/global/types'

export default defineComponent({
  props: {
    data: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  setup(props: any) {
    const imgs = ['fybmh.png', 'baznh.png', 'jcsjh.png']
    const innerData = ref<IComObj[]>([])

    innerData.value = props.data
    imgs.forEach((item: string, index: number) => {
      innerData.value[index].img = require('@/assets/images/WReconsideration/' +
        item)
    })

    return {
      innerData
    }
  }
})
