
import { defineComponent } from 'vue'
import ComBox from '@/components/comBox/wComBox.vue'
import Part1 from './cpns/part1.vue'
import Part2 from './cpns/part2.vue'
import Part3 from './cpns/part3.vue'
import Part4 from './cpns/part4.vue'
import part5 from './cpns/part5.vue'
import { allData } from '../config/WReconsideration.config'

export default defineComponent({
  name: 'WReconsideration',
  components: {
    ComBox,
    Part1,
    Part2,
    Part3,
    Part4,
    part5
  },
  setup() {
    return {
      allData
    }
  }
})
