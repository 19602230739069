/*
 * @Author: wangjie
 * @Date: 2022-07-19 10:11:39
 * @Description: 文件描述
 */
export const allData = {
  cases: [
    {
      img: 'qgxzfygzpt.png',
      name: '全国行政复议与行政应诉工作平台',
      content:
        '全国行政复议与行政应诉工作平台涵盖公众服务、办案管理、在线听证、分析研判、典型案例和队伍管理等内容，实现行政复议服务便捷化、办案智能化、管理科学化、决策精准化、公开常态化，推动全国行政复议案件基础数据的采集汇聚和利用'
    },
    {
      img: 'bjxzfy.png',
      name: '北京市行政复议与应诉工作平台',
      content:'北京市行政复议与应诉工作平台包括工作门户、复议办案驾驶舱、移动办案、应诉办案、在线听证、典型案例、分析研判、人员队伍管理、实体复议接待大厅及质效评估等系统，实现社会公众和行政复议机关“双在线”的行政复议新模式，达到极简化、智能化、便捷化、数据化'
    },
    {
      img: 'xzzzqxzfygzpt.png',
      name: '西藏自治区行政复议与行政应诉工作平台',
      content:
        '西藏自治区行政复议与行政应诉工作平台包括工作门户、案件办理、掌上复议、移动办案、数据管理、窗口服务、应诉管理、在线听证、数据分析等系统，结合西藏地域特色，实现业务系统“汉语+藏语”双语显示，打破语言障碍，提高沟通效率。减轻办案人员压力，缓解“案多人少”矛盾，提升办案质量和效率。达到了办案规范化、沟通高效化、决策数据化。形成西藏自治区行政复议工作“横向到边，纵向到底”的新格局'
    },
  ],
  intro: [
    {
      img:'wsfwxt.png',
      name: '网上服务系统',
      content:
        '提供复议百科、智能咨询、复议申请、进度查询、文书公开、复议机关等功能，方便群众通过网络参与行政复议，加强行政复议机关与社会互动，提升服务公众的能力'
    },
    {
      img:'zsfyxcx.png',
      name: '掌上复议移动端',
      content:
        '为适应现代移动互联的快速发展，实现行政复议服务全民普惠化，随时随地获取“全、新、快、准”的服务资源，小程序不仅包含了行政复议服务平台全部功能，同时也支持移动端在线听证'
    },
    {
      img:'zzfwytj.png',
      name: '自助服务一体机',
      content:
        '行政复议自助服务一体机是一款集多功能于一体的智能化服务设备，为用户提供高效、便捷的复议服务。用户可以通过一体机轻松完成复议申请、查询复议进度、获取复议结果等操作，同时支持ocr识别，复议申请书生成'
    },
    {
      img:'zxtzglxt.png',
      name: '在线听证系统',
      content:
        '支持各类案件参会人员通过PC端或小程序在线视频参与听证会议，会中语音文字转写，会后自动生成听证笔录，最终支持参会人员在线手写签字确认笔录内容'
    },

    {
      img:'xzfygzmh.png',
      name: '行政复议工作门户',
      content:
        '集通知公告、复议动态、综合查询、统计报表、信息报送等功能为一体的行政复议工作门户，打造了复议工作人员的网上家园'
    },
    {
      img:'xzfybaxt.png',
      name: '行政复议办案系统',
      content:
        '实现行政复议案件全流程在线办理、文书自动生成、流程在线审批、可视化监督等功能，全面提升办案效率'
    },
    {
      img:'xzysbaxt.png',
      name: '行政应诉办案系统',
      content:
        '面向应诉工作人员，提供一审、二审、再审的应诉案件登记、分配、办理、审批，实现全流程在线办理；支持行政应诉案件查询及统计分析'
    },
    {
      img:'ydbaapp.png',
      name: '移动办案系统',
      content: '方便办案人员随时随地进行案件办理、生成文书、流程审批，案件查询，及调查取证，全面提升办案效率'
    },
    {
      img:'dwglxt.png',
      name: '队伍管理系统',
      content:
        '行政复议队伍管理系统对复议机构、复议人员、被申请人及复议专家的信息进行统一整合，实现了行政复议队伍的全面管理'
    },
    {
      img:'sjfxypxt.png',
      name: '分析研判系统',
      content:
        '汇聚执法、复议、应诉数据信息，通过多指标关联分析，提高数据挖掘的广度和深度，增强“数据决策”能力，助力法治政府建设'
    },
    {
      img:'zxpgxt.png',
      name: '质效评估系统',
      content:
        '行政复议质效评估系统用于评估行政复议工作质量和效率的工具。系统通过收集、整理和分析行政复议案件数据，提供全面、客观的评估结果。量化评估行政复议工作的各项指标，发现存在的问题和不足，为改进工作提供有力支持'
    },
    {
      img:'znfzxt.png',
      name: '智能辅助系统',
      content:
        '结合大数据、人工智能等技术，实现复议案件办理智能化，包括智能登记、智能受理、智能审理等'
    },
  ],
  spots: [
    {
      name: '复议便民化',
      content:
        '面向社会公众，提供网上复议、“掌上复议”、自助服务一体机、在线听证等便捷服务，支持行政复议多终端办理， 方便社会公众随时随地提交复议申请、查看办理进度、参加复议听证，提升行政复议的便民化'
    },
    {
      name: '办案智能化',
      content: '面向办案人员，提供智能识别、语音转写、文书生成、文书校对、类案推荐、办案预警、法规推荐等智能化辅助，支持行政复议案件全流程智能化办理，让办案更智能、更规范、更高效'
    },
    {
      name: '决策数据化',
      content:
        '面向各级领导，提供复议案件受理、立案、审理、结案等多维度数据分析，动态展现复议案件分布情况、变化趋势，实时预警行政复议的苗头性、趋势性问题，为领导决策提供数据支撑'
    }
  ],
  moreCases: [
    {
      img: 'gzxzfyba.png',
      name: '广州市行政复议办案系统',
      content:'广州市行政复议办案系统包括案件办理及审批子系统、数据查询统计分析子系统，案件提醒子系统，督办催办子系统、电子公文送达子系统，移动办公子系统，系统接口子系统、后台管理子系统、安全保密子系统、全文检索子系统等10个子系统功能，通过行政复议平台的建设实现依法办案、高效办案，实现行政复议部门的法治政府构建'
    },
    {
      img: 'sxxzfy.png',
      name: '山西省行政复议与行政应诉工作平台'
    },
    {
      img: 'gzzffzypt.png',
      name: '贵州省行政复议平台',
      content:
        '包括基础数据管理、行政复议案件办理、案例管理、综合查询、行政复议统计、报表管理、网上申请及查询等7大功能，实现网上复议申请，案件进度查询，畅通复议渠道；实现行政复议案件全流程、规范化办理；实现对案件办理关键节点提供预警'
    },
    {
      img: 'hbsxzfy.png',
      name: '河北省行政复议与应诉工作平台'
    },
    {
      img: 'hnxzys.png',
      name: '湖南省行政应诉工作平台'
    },
    {
      img: 'gsxzfygzpt.png',
      name: '甘肃省行政复议工作平台',
      content:
        '甘肃行政复议工作平台包括行政复议案件办理系统、“掌上复议”微信小程序、行政复议数据分析研判系统等三个子系统。覆盖省级及兰州市级、县级行政复议办案人员、相关领导及全省社会公众，实现了行政复议全流程监督和指导，打造社会公众和行政复议机关“双在线”的行政复议新模式'
    },
    {
      img: 'xjzhfy.png',
      name: '新疆生产建设兵团智慧复议工作平台'
    },
    {
      img: 'szfzzfxxpt.png',
      name: '深圳法治政府信息平台',
      content:
        '包括基础信息管理、案件办理、案件填报、典型案例库、应诉管理、查询统计分析等6大功能，从诉访分流、繁简分流、复议听证、集体讨论、复议专递、文书公开等多个方面积极探索创新，全面实现行政复议全流程覆盖、规范化办理、智能化应用，打造行政复议的“深圳标准”'
    },
    {
      img: '',
      name: '',
      content: ''
    }
  ],
  views: [
    {
      name: '智能办案'
    },
    {
      name: '智能听证'
    },
    {
      name: '智能推荐'
    },
    {
      name: '智能检索'
    },
    {
      name: '智能识别'
    },
    {
      name: '智能归档'
    }
  ]
}
