
import { defineComponent, ref } from 'vue'
import { IComObj } from '@/global/types'

export default defineComponent({
  props: {
    data: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  setup(props: any) {
    const innerData = ref<IComObj[]>([])
    innerData.value = props.data
    return {
      innerData
    }
  }
})
