import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-523823f0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "part3-ul" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.innerData, (item, index) => {
      return (_openBlock(), _createElementBlock("li", { key: index }, [
        _createElementVNode("img", {
          src: item.img,
          alt: ""
        }, null, 8 /* PROPS */, _hoisted_2),
        _createElementVNode("h2", null, _toDisplayString(item.name), 1 /* TEXT */),
        _createElementVNode("p", null, _toDisplayString(item.content), 1 /* TEXT */)
      ]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}