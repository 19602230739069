import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-ba026ac6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "common-cont" }
const _hoisted_2 = { class: "part5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_part_1 = _resolveComponent("part-1")!
  const _component_part_2 = _resolveComponent("part-2")!
  const _component_com_box = _resolveComponent("com-box")!
  const _component_part_3 = _resolveComponent("part-3")!
  const _component_part_4 = _resolveComponent("part-4")!
  const _component_part_5 = _resolveComponent("part-5")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_part_1, {
      data: _ctx.allData.views
    }, null, 8 /* PROPS */, ["data"]),
    _createVNode(_component_com_box, {
      title: "产品简介",
      class: "part part2"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_part_2, {
          data: _ctx.allData.intro
        }, null, 8 /* PROPS */, ["data"])
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_com_box, {
      title: "产品亮点",
      class: "part part3"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_part_3, {
          data: _ctx.allData.spots
        }, null, 8 /* PROPS */, ["data"])
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_com_box, {
      title: "典型案例",
      class: "part part4"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_part_4, {
          data: _ctx.allData.cases
        }, null, 8 /* PROPS */, ["data"])
      ]),
      _: 1 /* STABLE */
    }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_part_5, {
        data: _ctx.allData.moreCases
      }, null, 8 /* PROPS */, ["data"])
    ])
  ]))
}